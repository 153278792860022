import axios from 'axios';

const SPREADSHEETS_URL = 'https://spreadsheets.google.com';
const NEW_SPREADSHEETS_URL = 'https://content-sheets.googleapis.com/v4/spreadsheets/';

const { VUE_APP_SHEETS_API_KEY } = process.env;

const getGoogleSheets = (key, range) => {
    if (VUE_APP_SHEETS_API_KEY) {
        const url = `${NEW_SPREADSHEETS_URL}${key}/values/${range}?key=${VUE_APP_SHEETS_API_KEY}`;

        return axios({
            method: 'get',
            url: url,
        })
            .then(response => response.data.values);
    }  else {
        const url = `${SPREADSHEETS_URL}/feeds/list/${key}/1/public/full?alt=json`;

        return axios({
            method: 'get',
            url: url,
        })
            .then(response => response.data.feed.entry);
    }
};

export {
    getGoogleSheets,
};
